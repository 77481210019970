.testimonial-container {
  display: flex;
  flex-direction: row;
  background: #f8f9fa;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 10px 20px; /* Adjusted padding for larger screens */
  position: relative;
  box-sizing: border-box; /* Include padding in width calculations */

  .overlay-testimonial {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }

  .expanded-testimonial-view {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    text-align: left;

    h3 {
      margin-top: 0;
    }

    img {
      width: 100%;
      max-width: 250px;
      height: auto;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
    }

    .testimonial-btn-close {
      margin-top: 10px;
      cursor: pointer;
      background-color: #7ab945;
      color: #fff;
      border: 1px #7ab945;
      padding: 0.5rem 0.8rem;
      font-weight: 600;
      border-radius: 6px;

      &:hover {
        transform: scale(1.07, 1.07);
        background: #071739;
      }
    }
  }

  .left-testimonial {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1.5rem;
    margin-top: 28px;
    margin-left: 40px;
    padding: 5px 2rem;
    width: 100%; /* Make it responsive */
    max-width: 730px;

    h1 {
      margin-left: 20px;
    }

    a {
      color: #fff;
      text-decoration: none;
      font-size: 0.9rem;
      background: #7ab945;
      opacity: 1;
      border-radius: 8px;
      margin-left: 1rem;
      padding: 0.5rem;

      &:hover {
        transform: scale(1.07, 1.07);
        background: #071739;
      }
    }

    .review-card {
      box-shadow: rgba(149, 157, 165, 0.2) 2px 8px 14px;
      background-color: #fff;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      height: 240px;
      justify-content: center;
      align-items: flex-start;
      overflow: hidden;
      padding: 20px 10px 20px 20px;
      margin: 1rem 1rem;

      .review-desc {
        font-size: 16px;
        overflow-y: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Number of lines to show */
        -webkit-box-orient: vertical;
        line-height: 1.5;
        max-height: 4.5em; /* line-height * number of lines */
        margin-left: 0;
        padding: 0 1rem;
        width: 95%;
        text-align: left;
      }

      .review-name {
        margin-top: 0.6rem;
        display: flex;
        flex-direction: row;
        gap: 0.9rem;
        align-items: center;
        margin-left: 1rem;

        img {
          border-radius: 50%;
          width: 80px;
          height: 80px;
          object-fit: cover;
        }

        p {
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }
  }

  .right-testimonial {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ratings {
      background: rgb(248, 244, 244);
      border-radius: 10px;
      bottom: 16%;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      height: 70px;
      left: 79%;
      max-height: 390px;
      padding: 20px;
      position: absolute !important;
      text-align: end;
      transform: translateX(-116%);
      width: 150px;

      h3 {
        text-align: end;
        font-size: 1.7em;
        margin: 0;
      }

      p {
        text-align: end;
        font-size: 12px;
        margin: 2px 0;
      }

      .stars {
        .star-icon {
          color: #ffc107;
          margin: 0 2px;
        }
      }
    }

    .img-ratings {
      position: relative;
      text-align: center;
      padding: 20px;
      margin-top: 80px;
      margin-right: 45px;

      img {
        height: 340px;
        width: 321px;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        opacity: 0.95;
      }
    }
  }

  /* Responsive adjustments */

  @media (min-width: 1800px) and (max-width: 2500px) {
    .testimonial-container {
      width: 98%;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;

    .left-testimonial {
      margin: 0;
      padding: 10px;
      width: 100%;
      max-width: none;
    }

    .right-testimonial {
      justify-content: center;
      margin-top: 20px;

      .ratings {
        position: static;
        transform: none;
        left: 20%;
        bottom: 7%;
        margin: 20px 0;
      }

      .img-ratings {
        margin: 20px 0;

        img {
          height: 320px;
          width: 100%;
          max-width: 290px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .left-testimonial .review-card {
      margin: 1rem 0.5rem;
    }

    .right-testimonial .ratings {
      margin: 10px 0;
      padding: 15px;
      width: auto;
      left: 21%;
      bottom: 6%;
    }

    .right-testimonial .img-ratings {
      padding: 10px;
      margin: 10px 0;

      img {
        max-width: 250px;
        height: 300px;
      }
    }
  }

  @media (max-width: 768px) {
    .left-testimonial {
      padding: 5px;

      .review-card {
        padding: 15px 5px;
        margin: 1rem 0.5rem;
      }
    }

    .right-testimonial {
      .ratings {
        padding: 9px 8px;
        width: 100px;
        left: 24%;
        bottom: 5%;

        h3 {
          font-size: 1.1em;
        }

        p {
          font-size: 12px;
        }
        .star-icon {
          width: 12px;
        }
      }

      .img-ratings {
        img {
          max-width: 220px;
          height: 250px;
        }
      }
    }
  }

  .expanded-testimonial-view {
    .testimonial-expanded-header {
      width: 100%;
    }
    div {
      width: 100%;
      p {
        width: 100%;
        text-wrap: wrap;
        text-align: justify;
      }
    }
  }

  @media (max-width: 576px) {
    .left-testimonial {
      padding: 5px;

      .review-card {
        padding: 10px;
        margin: 0.5rem 0.5rem;
      }
    }

    .right-testimonial {
      .ratings {
        position: relative;
        top: 83%;
        left: 10%;
        padding: 5px;
        width: 90px;

        h3 {
          font-size: 0.8em;
        }

        p {
          font-size: 0.7em;
        }
        .star-icon {
          width: 10px;
        }
      }

      .img-ratings {
        img {
          max-width: 180px;
          height: 200px;
        }
      }
    }
  }
}

@media (max-width: 490px) {
  .testimonial-container {
    .expanded-testimonial-view {
      display: flex;
      // justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 350px;
    }
  }
}

@media (max-width: 440px) {
  .testimonial-container {
    .expanded-testimonial-view {
      width: 320px;
    }
  }
}
