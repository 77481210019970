.contactUsCard-container {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // max-height: 100vh;
  padding: 10px; /* Adjust padding as needed */
  background: #f7f7f7;
  height: 465px;
  // margin-bottom: 1rem;
  max-height: 100%;

  h2 {
    font-size: 1.8rem;
  }
}

.contactUsCard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.e-card {
  background: transparent;
  border-radius: 16px;
  box-shadow: 0px 8px 28px -9px rgba(0, 0, 0, 0.45);
  height: 300px;
  margin: 20px;
  overflow: hidden;
  position: relative;
  width: 300px;

  &:hover {
    transform: scale(1.05);
    transition: ease 0.35s;
  }
}

.wave {
  position: absolute;
  width: 540px;
  height: 700px;
  opacity: 0.6;
  left: 0;
  top: 0;
  margin-left: -50%;
  margin-top: -55%;
  // background: linear-gradient(744deg, #7ab945, #071739 80%, #7ab945);
  background: linear-gradient(to bottom, #7ab945, #4e7e30);

  border-radius: 40%;
  animation: wave 55s infinite linear;
}

.wave:nth-child(2),
.wave:nth-child(3) {
  top: 210px;
}

.wave:nth-child(2) {
  animation-duration: 50s;
}

.wave:nth-child(3) {
  animation-duration: 45s;
}

.card-icon {
  width: 2rem;
  height: 2rem;
  margin-top: -2rem;
  // padding-bottom: 1em;
}

.infotop {
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: 5.9rem;
  left: 0;
  right: 0;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.infotop p {
  margin: 1rem;
}

.infotop h3 {
  font-size: 16px;
  margin: 0.5rem 0.5rem 1.2rem 0.5rem;
}

.infotop a {
  text-decoration: none;
  color: #ffffff;
}

.infotop:hover {
  cursor: pointer;
}

.name {
  font-size: 14px;
  font-weight: 100;
  position: relative;
  top: 1em;
  text-transform: lowercase;
}

.playing .wave {
  animation-duration: 3000ms;
}

.playing .wave:nth-child(2) {
  animation-duration: 4000ms;
}

.playing .wave:nth-child(3) {
  animation-duration: 5000ms;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-icon {
  margin: 0 5px;
  font-size: 1.5em;
  color: #ffffff;
}

/* Responsive adjustments */
// @media (max-width: 1200px) {
//   .e-card {
//     width: 250px;
//     height: 250px;
//   }
// }
// @media (max-width: 992px) {
//   .e-card {
//     width: calc(50% - 40px); /* Two cards per row with margin */
//     max-width: 300px; /* Limit maximum width */
//     height: 300px; /* Maintain height */
//     margin: 10px; /* Adjust margins */
//   }
// }

// @media (max-width: 768px) {
//   .e-card {
//     width: calc(100% - 20px); /* One card per row with margin */
//     max-width: 300px; /* Limit maximum width */
//     height: auto; /* Allow height to adjust based on content */
//     margin: 10px; /* Adjust margins */
//   }
// }

@media (max-width: 1100px) {
  .contactUsCard-container {
    height: auto;
  }
  .contactUsCard {
    flex-wrap: nowrap;
  }

  // .contact-pg-container .main-container .image-logo img {
  //   width: 110% !important;
  //   height: 500px !important;
  // }
}

@media (max-width: 768px) {
  .contactUsCard-container {
    height: auto;
  }
  .contactUsCard {
    flex-wrap: wrap !important;
  }

  // .contact-pg-container .main-container .contact-details{
  //   margin-left: 2rem !important;
  // }

  // .contact-pg-container .main-container .image-logo {
  //       margin-top: 5rem;
  //   .image-text {
  //     width: 110% !important;
  //     p {
  //       font-size: 0.9rem;
  //     }
  //   }
  // }

  // .contact-pg-container .main-container .contact-details form{
  //   margin-left: 1.8rem !important;
  // }
  
  // .contact-pg-container .main-container .image-logo .image-text h2 {
  //   margin-bottom: 0.5rem;
  // }
}

@media (max-width: 500px) {
  .contactUsCard-container {
    height: auto;
    .contactUsCard {
      flex-wrap: wrap !important;
    }
  }

//  .contact-pg-container .main-container .contact-details{
//     margin-left: 3rem !important;

//     form{
//       margin-left: 0 !important;
//     }
//   }

//   .contact-pg-container .main-container .image-logo .image-text {
//     width: 100%;
//   }
//   .contact-pg-container .main-container .image-logo img {
//     width: 100% !important;
//     height: 400px !important;
//   }
// }
}