.all-projects-main-container {
  // .all-project-image-div {
  // }

  .all-projects-filter-div {
    display: flex;
    // margin-left: 1rem;
    gap: 2rem;
    margin-bottom: 3rem;
    margin-top: 1rem;
    background: #071739;
    height: 75px;
    align-items: center;

    div {
      position: relative;
      margin-left: 1rem;

      select {
        border-radius: 8px;
        padding: 0.5rem 1rem;
        padding-right: 4rem;
        background-color: #e1e1e17d;
        border: 1px solid #cccccc7d;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        font-size: 1rem;
        color: #fff;
        cursor: pointer;
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
          border-color: #007bff;
          box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
          outline: none;
        }

        option {
          background-color: #ffffff;
          color: #333333;
        }
      }
    }
  }

  .all-projects-project-display-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .all-projects-project-display {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      justify-content: center;
      gap: 1rem;
    }
  }

  .propertygrid-pagination-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;

    p {
      margin-top: 1rem;
      font-size: 1rem;
      color: #666666;
    }
  }
}

//responsive design

@media (max-width: 770px) {
  .all-projects-main-container {
    .all-projects-project-display-div {
      .all-projects-project-display {
        width: 55%;
      }
    }
  }
}

@media (max-width: 715px) {
  .all-projects-main-container {
    .all-projects-filter-div {
      div {
        select {
          padding-right: 3rem;
          option {
            padding: 0.3rem; // Reduce option padding for responsiveness
            font-size: 0.9rem; // Smaller font size for options
          }
        }
      }
    }
  }
}

@media (max-width: 560px) {
  .all-projects-main-container {
    .all-projects-filter-div {
      flex-direction: column;
      height: auto;
      align-items: flex-start;
      padding: 1rem 0;
      gap: 1rem;

      div {
        select {
          padding-right: 0;
          font-size: 0.8rem;
        }
      }
    }
    .all-projects-project-display-div {
      .all-projects-project-display {
        width: 80%;
      }
    }
  }
}

@media (max-width: 440px) {
  .all-projects-main-container {
    .all-projects-filter-div {
      div {
        select{
           option {
            padding: 0; // Reduce option padding for responsiveness
            font-size: 0.7rem; // Smaller font size for options
          }
        }
        .city-all-projects {
          padding-right: 3rem;
        }
        .bhk-all-projects {
          padding-right: 1.7rem;
        }
      }
    }
  }
}
