/* Facilities.css */

.facilities-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding: 20px;
  padding-bottom: 2rem;
}
.facility-card {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.facility-card:hover {
  transform: scale(1.05);
}

.facility-card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
}

.facility-card-title {
  flex: 1;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  padding: 10px;
}

.facility-card-image {
  background-size: cover; /* Ensures the image covers the entire area */
  background-position: center; /* Centers the image */
  height: 60%; /* Takes up the bottom half of the card */
  width: 100%;
  position: absolute;
  bottom: 0; /* Positions the image at the bottom */
  left: 0;
  z-index: 0;
}

.facility-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
