/* ServiceForm.css */

.contact-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-form h2 {
    text-align: start;
    margin: 0;
    font-size: 28px;
    color: #333;
    border-bottom: 2px solid #009933;
    padding-bottom: 10px;
}

.form-group {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-wrap: wrap;
}

.form-group input,
.form-group textarea {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: calc(33.33% - 20px); /* Initial width for large screens */
    flex: 1;
}

.form-group textarea {
    resize: vertical;
    min-height: 60px;
    flex: 1 1 100%;
}

.contact-form button {
    background: #7ab945;
    border: 1px solid #7ab945;
    width: 30%; /* Initial width for large screens */
    color: #fff;
    border: none;
    padding: 12px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.contact-form button:hover {
    background-color: #fff;
    color: #7ab945;
    border: 1px solid #7ab945;
}

.toggle-fields {
    display: none;
    gap: 15px;
}

.horizontal-form {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    align-items: center;
}

#toggleButton {
    flex: 0 0 auto;
    width: 40%; /* Initial width for large screens */
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #7ab945;
    background: #7ab945;
    color: #fff;
    cursor: pointer;
    transition: background 0.3s ease;
}

#toggleButton:hover {
    background: #fff;
    color: #7ab945;
}

@media (max-width: 992px) {
    .form-group input,
    .form-group textarea {
        width: calc(50% - 20px); /* 2 columns on medium screens */
    }
    .contact-form button {
        width: 40%; /* Adjusted width for medium screens */
    }
    #toggleButton {
        width: 40%; /* Adjusted width for medium screens */
    }
}

@media (max-width: 768px) {
    .form-group input,
    .form-group textarea {
        width: calc(50% - 20px); /* 2 columns on tablets */
    }
    .contact-form button {
        width: 100%; /* Full width on tablets */
    }
    #toggleButton {
        width: 100%; /* Full width on tablets */
    }
}

@media (max-width: 568px) {
    .form-group input,
    .form-group textarea {
        width: 100%; /* Full width on large smartphones */
    }
    .contact-form button {
        width: 100%; /* Full width on large smartphones */
    }
    #toggleButton {
        width: 100%; /* Full width on large smartphones */
    }
}

@media (max-width: 480px) {
    .form-group input,
    .form-group textarea {
        width: 100%; /* Full width on small smartphones */
    }
    .contact-form button {
        width: 100%; /* Full width on small smartphones */
    }
    #toggleButton {
        width: 100%; /* Full width on small smartphones */
    }
}
