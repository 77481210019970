.main-home-container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  overflow-x: hidden;
  /* border: 10px solid blue; */
}
.main-home-container:nth-child(n-1) {
  gap: 0;
}

.view-all-prop-link {
  text-decoration: none;
  color: #262626;
  font-size: 1.1rem;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

.home_image_container {
  position: relative;
  background-size: cover;
  background-position: center;
}

.home_page_image_overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_page_title {
  color: white;
  text-align: center;
  letter-spacing: 0.2rem;
  /* Remove any transition or animation properties */
}

.home-page-filter_div {
  display: flex;
  flex-direction: row !important;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: transparent;
  border-radius: 1rem;
}

#home-page-search_location {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #7ab945;
  color: #fff;
  border: none;
  font-size: larger;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

#home-page-search_location:hover {
  background-color: white;
  color: #7ab945;
}

.search_container {
  display: flex;
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 10px;
  flex-grow: 1;
  position: relative;
}

.search_icon {
  position: absolute;
  color: black;
  left: 10px;
}

.search_input {
  height: 2rem;
  padding: 0.3rem 0.5rem 0.3rem 2.5rem;
  flex-grow: 1;
  border: none;
  border-radius: 10px;
}

.search_input::placeholder {
  color: black;
}

.search_filter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7ab945;
  /* border: 1px solid white; */
  padding: 0.5rem;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.home_page_search_submit {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7ab945;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 10px;
  font-size: larger;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.home_page_search_submit:hover {
  background-color: white;
  color: #7ab945;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  width: 40%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.close {
  position: absolute;
  top: -0.1rem;
  right: 0.5rem;
  font-size: 24px;
  cursor: pointer;
  color: black;
}

.image-options {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

.image-options div {
  width: 100%;
  border-radius: 10px;
  padding-top: 75%;
  position: relative;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  transition: transform 0.3s;
}

.image-options div div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.image-options div:hover {
  transform: scale(1.1);
}

.popup-content h2 {
  color: #071739;
  margin-top: -10px;
  text-align: center;
  font-weight: 900;
  margin-bottom: 1rem;
}

.home_span {
  color: #7ab945;
}

.section-city {
  padding: 80px 0;
}

.title-wrap {
  margin-bottom: 30px;
}

.sectiontitle h2 {
  font-size: 32px;
  font-weight: 700;
}

.image-options div div h5 {
  margin-top: -4rem;
}

#home-page-search_location:hover {
  background-color: white !important;
  color: #7ab945 !important;
}

.search_filter:hover {
  background-color: white !important;
  color: #7ab945 !important;
}

.home_page_search_submit:hover {
  background-color: white !important;
  color: #7ab945 !important;
}

.home_image_container {
  position: relative;
  height: 80vh;
  background-size: cover;
}

/* .tracking-in-contract-bck {
  display: inline-block;
} */

.home-page-modal-overlay {
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.home-page-modal-content {
  background-color: rgb(0, 0, 0, 0.5);
  width: 100%;
  padding: 20px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
}

.home-page-close-button {
  border: none !important;
  position: absolute;
  top: 14%;
  right: 35%;
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
  color: #7ab945;
  background-color: transparent !important;
}

@media screen and (max-width: 768px) {
  .home-page-modal-overlay {
    align-items: flex-start;
    justify-content: start;
    left: 1rem;
    top: auto;
  }
  .home-page-modal-content {
    width: 95vw;
    max-width: 90vw;
    padding: 15px;
  }
}

@media screen and (max-width: 568px) {
  .home-page-modal-overlay {
    align-items: flex-start;
    justify-content: start;
    left: -10rem;
  }
  .home-page-modal-content {
    left: 9.5rem;
  }

  .home-page-close-button {
    top: 10%;
    right: 6%;
  }
}

@media screen and (max-width: 1024px) {
  .main-home-container {
    gap: 3rem;
  }

  .popup-content {
    width: 80%;
    max-width: 600px;
    padding: 2rem;
  }

  .image-options {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .main-home-container {
    z-index: 10;
    gap: 2rem;
  }

  .popup-content {
    width: 90%;
    max-width: 500px;
    padding: 1.5rem;
  }

  .image-options {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .main-home-container {
    gap: 1.5rem;
  }

  .popup-content {
    width: 95%;
    max-width: 400px;
    padding: 1rem;
    overflow: auto;
    height: 500px;
  }
}

@media screen and (max-width: 568px) {
  .main-home-container {
    z-index: 1;
    gap: 1rem;
  }

  .popup-content {
    width: 95%;
    max-width: 300px;
    padding: 0.5rem;
  }

  .image-options {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }
}

@media screen and (max-width: 568px) {
  .home_image_container {
    height: 40vh;
  }
  .popup-content h2 {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 700;
  }
}
