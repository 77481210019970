.contact-pg-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.contact-pg-popup-content {
  background-color: white;
  padding: 20px;
  top: 20%;
  width: auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 100%;
  text-align: center;
  // background-color: #eff3db;
  border-color: #c4ded3;
  box-shadow: 2px 6px 6px 2px rgba(0, 0, 0, 0.2);

  .contact-pg-popup-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .contact-pg-popup-icon {
    margin-bottom: 18px;
    font-size: 20px;
    margin-right: 10px; // Adjust margin-right as needed
    color: #6ade6a;
  }

  p {
    margin: 0;
    margin-bottom: 20px;
    padding: 10px 10px;
  }
}

.contact-pg-popup-content button {
  padding: 10px 20px;
  background-color: #7ab945;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-pg-popup-content button:hover {
  background-color: #7ab945;
}

/* Media Query for responsiveness */
@media (max-width: 768px) {
  .contact-pg-popup-content {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .contact-pg-popup-content {
    max-width: 90%;
    width: 70%;
    height: 125px !important;
  }

  .contact-pg-popup-icon {
    font-size: 30px !important;
    margin-bottom: 37px !important;
    margin-right: 0 !important;
  }
  .contact-pg-btn {
    font-size: 14px;
  }
}
