.s-property-main-container {
  margin-left: 14rem;

  p,
  h3 {
    margin: 0;
  }

  .s-property-details {
    display: flex;

    .s-property-details-left {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 1rem;

      .s-property-home-link {
        width: fit-content;
        p,
        a {
          font-size: 0.7rem;
          font-weight: 450;
        }
        a {
          text-decoration: none;
          color: rgb(0, 0, 0, 0.8);

          &:hover {
            color: #293479;
            font-weight: 500;
          }
        }
      }

      .s-property-name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.4rem;

        h3 {
          font-size: 1.5rem;
        }

        p {
          font-size: 0.9rem;

          .developer-name {
            color: #202d80;
            font-weight: 700;
          }
        }
      }

      .s-property-rera {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        p {
          font-size: 0.7rem;
          font-weight: 500;
          border: 1px solid rgb(255, 255, 255);
          padding: 0.2rem 0.4rem;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
            rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        }
        p:nth-child(1) {
          display: flex;
          align-items: center;
          gap: 0.2rem;
          // justify-content: center;
        }

        p:nth-child(2) {
          opacity: 0.6;
        }

        .rera-check {
          font-size: 0.68rem;
        }
      }
    }

    .s-property-details-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      align-items: end;
      justify-content: end;
      margin-right: 12rem;
      p {
        font-size: 0.7rem;
        font-weight: 500;
      }

      .s-property-buttons {
        margin-top: 0.3rem;
        display: flex;
        flex-direction: row;
        gap: 0.4rem;
        // align-items: center;

        .tooltip {
          position: relative;
          display: inline-block;

          .tooltiptext {
            visibility: hidden;
            width: auto; /* Adjust width as needed */
            background-color: black;
            color: white;
            text-align: center;
            border-radius: 6px;
            padding: 0.31rem 0.62rem;
            position: absolute;
            z-index: 1;
            bottom: calc(100% + 10px); /* Adjust spacing from the button */
            left: 50%;
            transform: translateX(-50%); /* Center horizontally */
            opacity: 0;
            /* Fade in tooltip */
            transition: opacity 0.3s;
          }

          .tooltiptext::before {
            content: "";
            position: absolute;
            top: 100%; /* Position at the bottom of the tooltip */
            left: 50%;
            margin-left: -5px; /* Adjust based on arrow width */
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent transparent;
          }

          &:hover .tooltiptext {
            visibility: visible;
            opacity: 1;
          }
        }

        button {
          height: 35px;
          width: auto;
          text-align: center;
          font-size: 0.9rem;

          &:hover {
            transform: scale(1.1, 1.1);
          }
        }

        .btn-same {
          background: white;
          color: black;
          border: 1px solid #7ab945;
          border-radius: 4px;
        }

        .btn-brochure {
          display: flex;
          gap: 0.2rem;
          align-items: center;
          padding: 0 0.5rem;
        }

        .btn-contact {
          background: #7ab945;
          border-radius: 4px;
          border: 1px solid #7ab945;
          color: #fff;
          font-weight: 600;
          padding: 0.5rem 1.2rem;
        }

        .btn {
          padding-left: 0.6rem;
          padding-right: 0.6rem;
        }
      }
    }
  }

  .s-property-images {
    margin-top: 2.5rem;
    margin-right: 11.4rem;
    .s-property-images-flex {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      row-gap: 1rem;
      justify-content: space-evenly;

      .s-property-image-thumbnail {
        flex: 1 1 150px; // Adjust the size as needed
        cursor: pointer;
        transition: transform 0.2s;
        max-width: calc(28.333% - 10px); // Three images per row with gap
        // height: 250px;
        height: auto;
        object-fit: cover;
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .image-modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100000;

      .image-modal-content {
        position: relative;

        max-width: 70%;
        max-height: 90%;

        .selected-image {
          width: 100%;
          height: 550px;
        }

        // .close-modal {
        //   position: absolute;
        //   top: 10px;
        //   right: 10px;
        //   background: transparent !important;
        //   border: none;
        //   padding: 10px;
        //   cursor: pointer;
        // }

        .modal-prev,
        .modal-next,
        .close-modal {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: rgba(0, 0, 0, 0.7);
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          padding: 10px;
          font-size: 20px;
        }

        .modal-prev {
          left: 0;
        }

        .modal-next {
          right: 0;
        }

        .close-modal {
          top: 18px;
          right: 0px;
          padding: 5px;
        }
      }
    }
  }

  .s-property-desc {
    display: flex;
    justify-content: center;
    height: 50px;
    width: 84%;
    margin: auto;
    margin-left: 2rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    gap: 2rem;

    .s-property-desc-config {
      border-right: 1px solid #202d80;
      padding-right: 2.5rem;
      height: 100%;

      &:last-child {
        border-right: none;
      }
    }

    #s-property-title {
      font-weight: 700;
      font-size: 1.2rem;
    }
  }
  hr {
    margin-left: 0;
    width: 84%;
    opacity: 0.5;
  }
}

.share-container {
  position: absolute;
  width: 250px;
  background: white;
  height: 100px;
  border: 1px solid;
  border-radius: 5px;
  left: -320%;
  top: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  z-index: 100;

  a {
    text-decoration: none;
    color: #262626;
  }
}

@media (min-width: 1800px) and (max-width: 2000px) {
  .s-property-main-container .s-property-desc .s-property-desc-config {
    border-right: 1px solid #202d80;
    padding-right: 5.5rem;
    padding-left: 2.5rem;
    height: 100%;
  }

  // .s-property-desc {
  //   width: 90% !important;
  // }
  .single-property-page-container .s-property-page-links {
    top: 11%;
  }
  .s-property-image-thumbnail {
    height: 300px !important;
    &:hover {
      transform: scale(1.02) !important;
    }
  }
}

@media (max-width: 1600px) {
  .s-property-main-container {
    .s-property-images {
      .s-property-images-flex {
        .s-property-image-thumbnail {
          height: 300px;
        }
      }
    }
  }
}

@media (max-width: 1025px) {
  .s-property-main-container {
    margin-left: 2rem;
    // width: 100%;
    .s-property-details {
      .s-property-details-left,
      .s-property-details-right {
        margin-right: 2rem;
      }
    }
    .s-property-images {
      margin-right: 2rem;

      .image-modal {
        .image-modal-content {
          .selected-image {
            height: auto;
          }
        }
      }
    }

    .s-property-desc {
      margin-right: 2rem;
    }
  }
}

@media (max-width: 992px) {
  .s-property-main-container {
    margin-left: 2rem;
    width: 85%;

    .s-property-details {
      flex-direction: column;

      .s-property-details-left,
      .s-property-details-right {
        margin-right: 0;
      }

      .s-property-details-right {
        margin-top: 1rem;
      }
    }

    .s-property-images {
      margin-right: 0;
    }

    .s-property-desc {
      width: 90%;
      margin-left: 1rem;
    }
  }
}

@media (max-width: 768px) {
  .s-property-main-container {
    margin-left: 2rem;
    width: 90%;

    .s-property-details {
      .s-property-details-left,
      .s-property-details-right {
        margin-right: 0;
      }

      .s-property-details-right {
        margin-top: 1rem;
      }
    }

    .s-property-images {
      margin-right: 0;
    }

    .s-property-desc {
      width: 100%;
      margin-left: 0.5rem;
      gap: 1rem;
      margin-left: 1rem;
      margin-bottom: 3rem;
      .s-property-desc-config {
        padding-right: 1.5rem;
      }
    }
  }
}

@media (max-width: 568px) {
  .s-property-main-container {
    margin-left: 0.5rem;

    .s-property-details {
      .s-property-details-left,
      .s-property-details-right {
        margin-right: 0;
      }

      .s-property-details-right {
        margin-top: 1rem;
      }
    }

    .s-property-images {
      margin-right: 0;

      .s-property-images-flex {
        row-gap: 0.5rem;
      }
    }

    .s-property-desc {
      width: 100%;
      margin-left: 0;
      gap: 1rem;
      margin-bottom: 1rem;
      // overflow-x: scroll;
    }
    .s-property-about-container
      .s-property-about
      .s-property-about-config-container {
      gap: 1.8rem !important;
    }
  }
}

@media (max-width: 480px) {
  .s-property-main-container {
    margin-left: 1rem;
    // .s-property-images {
    //   .s-property-images-flex {
    //     display: flex;
    //     flex-wrap: wrap;

    //     .s-property-image-thumbnail {
    //       width: 100%;
    //       max-width: 100%;
    //       height: auto;
    //       cursor: pointer;
    //       transition: transform 0.2s;
    //     }

    //     .s-property-image-thumbnail:hover {
    //       transform: scale(1.05);
    //     }
    //   }
    // }

    .s-property-images {
      .s-property-images-flex {
        .s-property-image-thumbnail {
          max-width: 100%;
          width: 150px;
          height: 150px;
        }
      }
    }

    .s-property-details {
      .s-property-details-left {
        .s-property-name {
          p {
            text-align: left;
          }
        }
      }

      .s-property-details-right {
        margin-right: 1rem;
        align-items: flex-start;
        .s-property-buttons {
          flex-direction: row-reverse;
        }
      }
    }

    .s-property-desc {
      margin-top: 1rem;
      flex-wrap: wrap;
      justify-content: space-evenly;
      height: auto;
      .s-property-desc-config {
        padding: 0;
        border: 0;
        // margin-right: 1rem !important;
        #s-property-title {
          font-size: 1rem;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 440px) {
  .s-property-main-container {
    .s-property-images {
      .s-property-images-flex {
        .s-property-image-thumbnail {
          max-width: 100%;
          width: 150px;
        }
      }
    }
  }
}

@media (max-width: 415px) {
  .s-property-main-container {
    margin-left: 0.5rem;
  }
}

@media (max-width: 395px) {
  .s-property-main-container {
    margin-left: 1rem;
    .s-property-details {
      .s-property-details-right {
        .s-property-buttons {
          .share-container {
            width: 185px;
            height: 75px;
            gap: 1.5rem;
          }
        }
      }
    }
  }
}


