/* .assets-intro-container-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 78vh; Or set to desired height
  }
  .assets-intro-left-side img{
    width: 30%;
  }
  .assets-intro-right-side {
    width: 100%; Ensures that the div takes the full width of its grid cell
    display: flex;
    flex-direction:column ;
    justify-content: center;
    align-items: center;
    border: 1px solid blue;
  }
  
  Add more specificity if necessary
  div.app .assets-intro-container-div .assets-intro-left-side,
  div.app .assets-intro-container-div .assets-intro-right-side {
    width: 100%;
  }
   */

.assets-intro-container-div {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
}

.intro-bg-img {
  position: relative;
  width: 530px;
}
.intro-assets-logo {
  position: absolute;
  width: 180px;
  object-fit: contain;
  left: 2.5%;
  top: 1.5%;
}

.assets-intro-left-side {
  /* margin-left: 1.5rem; */
  width: 30%;
}
.assets-intro-right-side {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
  }

  p,
  h1 {
    width: 90%;
    text-align: center;
  }
  p {
    text-align: justify;
  }
  p:last-child {
    margin-top: 0;
  }
}

/* responsive design */

@media (min-width: 1900px) and (max-width: 2000px) {
  .assets-intro-container-div {
    gap: 4rem;
  }

  .intro-assets-logo {
    left: 3.5%;
    top: 2%;
  }
}

@media (max-width: 1500px) {
  .intro-bg-img {
    width: 460px;
  }
  .intro-assets-logo {
    left: 2.5%;
    top: 0.5%;
  }
}

@media (max-width: 1200px) {
  .intro-bg-img {
    width: 450px;
  }
  .intro-assets-logo {
    width: 140px;
    top: 20%;
  }
}

@media (max-width: 900px) {
  .assets-intro-left-side {
    width: 30%;
  }
  .assets-intro-right-side {
    width: 70%;
  }
  .intro-bg-img {
    width: 300px;
  }
}

@media (max-width: 768px) {
  .assets-intro-container-div {
    gap: 1.5rem;
  }

  .assets-intro-right-side {
    p {
      font-size: 14px;
    }
  }
  .intro-bg-img {
    width: 250px;
  }
  .intro-assets-logo {
    width: 80px;
    top: 21.8%;
    left: -0.4%;
  }
}

@media (max-width: 566px) {
  .assets-intro-container-div {
    flex-direction: column;
  }

  .assets-intro-left-side,
  .assets-intro-right-side {
    width: 100%;
  }
}

@media (max-width: 440px) {
  .assets-intro-container-div {
    gap: 0.5rem;
    margin-top: 2rem;
  }

  .intro-bg-img {
    width: 220px;
  }

  .intro-assets-logo {
    width: 82px;
    top: 9.8%;
    left: 24%;
  }
}

@media (max-width: 415px) {
  .intro-assets-logo {
    left: 23%;
    top: 9.3%;
  }
}

@media (max-width: 395px) {
  .intro-assets-logo {
    left: 22%;
  }
}

@media (max-width: 376px) {
  .intro-assets-logo {
    left: 23%;
  }
}