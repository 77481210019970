.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.progress-bar {
  width: 80%;
  max-width: 400px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
}

.progress {
  width: 0;
  height: 100%;
  background-color: #7ab945;
  animation: fill 4s infinite;
}

@keyframes fill {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
