.property-container {
  width: 90vw;
  margin: auto;
  margin-left: 5rem;
  padding: 20px;
  /* border: 1px solid blue; */
}

.property-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.property-card-wrapper {
  padding: 10px;
  box-sizing: border-box;
}

.property-card {
  width: 90%;
  /* border: 1px solid black; */
  border-radius: 8px;
  height: 650px;
  /* margin: 1rem auto; */
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 0px;
  background-color: #fff;
}

.property-card-head {
  position: relative;
  border-bottom: 1px solid gray;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .property-container {
    margin-left: 2.2rem;
  }
}

@media (max-width: 768px) {
  .property-container {
    margin-left: 1.2rem;
  }
}
@media (max-width: 414px) {
  .property-container {
    margin-left: 0.8rem;
  }
 
}
@media (max-width: 360px) {
  .property-container {
    margin-left: -0.8rem;
  }
}
@media (max-width: 390px) {
  .property-container {
    margin-left: -0.5rem;
  }
}
@media (max-width: 376px) {
  .property-container {
    margin-left: 0.5rem;
  }
}
/* @media (max-width: 320px) {
  .property-container {
    margin-left: 1rem;
  }
} */
