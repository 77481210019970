.contact-pg-container {
  visibility: visible; /* Ensure visibility is set to visible */
  display: flex;
  flex-direction: column;

  .fade-up {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .fade-up.aos-animate {
    opacity: 1;
  }
  .aos-animate {
    opacity: 1;
    transform: translateY(0);
    /* Adjust properties based on your animation needs */
  }

  .contact-main-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    gap: 1rem;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 118%;
      height: 100%;
      background-image: url("../Images/logo5.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center right;
      opacity: 0.5;
      z-index: -1;
    }

    .image-logo {
      position: relative;
      width: 100%;
      margin-bottom: 20px;
      margin-top: 2rem;

      img {
        width: 100%;
        height: auto;
        border-radius: 6px;
      }

      .image-text {
        position: absolute;
        bottom: 0.6%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        padding: 12.5px 1px;
        color: rgba(255, 255, 255, 0.9);
        background-color: rgba(0, 0, 0, 0.5);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        text-align: center;

        h2 {
          margin-top: 0;
          font-size: 1.5rem;
        }

        p {
          font-size: 1rem;
          margin: 0 1rem;
        }
      }

      .image-icons {
        cursor: pointer;
        position: absolute;
        bottom: 15px;
        right: 20px;
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
          font-size: 1.5rem;
          color: white;
        }
      }
    }

    .contact-details {
      max-width: 600px;
      height: auto;
      background: rgba(8, 8, 8, 0.4);
      border-radius: 1rem;
      width: 100%;
      padding: 0;
      margin-top: 2rem;
      margin-left: 6rem;

      form {
        height: 495px;
        padding-top: 0.6rem;
        display: flex;
      }

      .headings {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
          font-size: 1.75rem;
          text-align: center;
          margin-bottom: 0;
        }

        p {
          margin: 0;
          font-size: 1rem;
          text-align: center;
          margin-bottom: 0.5rem;
        }

        h1,
        p {
          color: #fff;
        }

        @media (min-width: 768px) {
          h1 {
            text-align: left;
            font-size: 2rem;
          }

          p {
            text-align: left;
          }
        }
      }

      form {
        margin-left: 3.8rem;
        width: 100%;

        textarea {
          width: 100%;
          height: 100px;
          // margin-top: 5px;
          // padding: 10px;
          border-radius: 6px;
          border: 1px solid #bdbcbc;
          font-size: 1rem;
        }

        .input-group {
          width: 70%;
          margin-bottom: 1rem;
          display: flex;
          flex-direction: column;

          .label-icon {
            display: flex;
            align-items: center;

            .icon {
              margin-right: 10px;
              color: #fff;
            }
          }

          label {
            display: block;
            margin-bottom: 8px;
            align-self: center;
            font-weight: 700;
            font-size: 1rem;
            color: #fff;
          }

          input {
            width: 100%;
            padding: 10px;
            border-radius: 6px;
            border: 1px solid #bdbcbc;
            font-size: 1rem;
          }

          .invalid {
            border: 1px solid red;
          }

          .valid {
            border: 1px solid green;
          }
        }

        button {
          display: block;
          width: 100%;
          max-width: 300px;
          margin: 1rem 0.6rem;
          padding: 1rem 3rem;
          border-radius: 6px;
          border: none;
          background-color: #77b048;
          color: #fff;
          cursor: pointer;
          transition: background-color 0.3s ease;
          font-size: 1rem;
          font-weight: 600;

          &:hover {
            color: #7ab945;
            background: #fff;
          }
        }
      }
    }

    @media (max-width: 567px) {
      .image-logo {
        img {
          width: 100%;
          max-width: 300px;
          height: auto;
          border-radius: 6px;
        }

        .image-text {
          width: 100%;
          max-width: 300px;
          h2 {
            font-size: 1.2rem;
          }
          p {
            font-size: 0.8rem;
          }
        }
      }

      .contact-details {
        margin: auto;
        margin-bottom: 2rem;
        padding: 0;
        width: 80%;

        .error {
          left: 35%;
        }

        form {
          height: auto;
          margin: 0;
          padding: 0;
          width: 100%;
          align-items: center;

          .full-name,
          .email,
          .phone,
          .description-text {
            width: 100%;

            .input-group {
              width: 100%;
              display: block;

              .label-icon {
                justify-content: center;
              }
              input,
              textarea {
                width: 75%;
              }
            }
          }

          button {
            width: 80%;
          }
        }
      }
    }

    @media (min-width: 568px) and (max-width: 767px) {
      .image-logo {
        img {
          width: 100%;
          max-width: 400px;
          height: auto;
          border-radius: 6px;
        }

        .image-text {
          width: 100%;
          max-width: 400px;
          h2 {
            font-size: 1.3rem;
          }
          p {
            font-size: 1rem;
          }
        }
      }

      .contact-details {
        margin-left: 0;
        margin-bottom: 5rem;
        width: 80%;
        .headings {
          justify-content: center;
        }

        form {
          // padding: 1rem 2rem 3rem 0;

          button {
            margin-left: 1.5rem;
          }
        }
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      flex-direction: column;
      align-items: center;
      padding: 20px;

      .image-logo {
        flex: 0.4;
        margin-right: 10px;
        margin-left: 0;
        margin-bottom: 0;
        width: 570px;

        img {
          width: 100%;
          height: 600px;
          border-radius: 6px;
        }

        .image-text {
          left: 0;
          transform: none;
          width: 100%;
          border-radius: 0 0 6px 6px;
          p {
            font-size: 0.8rem !important;
          }
        }
      }

      .contact-details {
        flex: 0.6;
        margin-top: 0;
        padding: 0;
        margin-left: 0;

        form {
          button {
            max-width: 70%;
            margin: 1rem 0;
          }
          margin-left: 3.5rem;
        }
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      flex-direction: row;
      align-items: flex-start;
      padding: 30px;

      .image-logo {
        flex: 0.4;
        margin-right: 20px;

        img {
          width: 100%;
          height: 600px;
          border-radius: 6px;
        }

        .image-text {
          left: 0;
          transform: none;
          width: 100%;
          border-radius: 0 0 6px 6px;
        }
      }

      .contact-details {
        flex: 0.6;
        margin-top: 0;
        padding: 0;

        form {
          margin-left: 2.7rem;
          // button {
          //   max-width: 250px;
          // }
        }
      }
    }

    @media (min-width: 1200px) {
      flex-direction: row;
      align-items: flex-start;
      padding: 3rem;

      .image-logo {
        flex: 0.4;
        margin-right: 20px;

        img {
          width: 100%;
          height: auto;
          height: 650px;
          border-radius: 6px;
        }

        .image-text {
          left: 0;
          transform: none;
          width: 100%;
          border-radius: 0 0 6px 6px;
        }
      }

      .contact-details {
        flex: 0.6;
        padding: 0;

        form {
          button {
            max-width: 400px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .contact-details {
    .error {
      left: 30%;
    }
  }
}
