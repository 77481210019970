.area-wise-property-display {
  .property-area-wise-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}
// .area-wise-form {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   gap: 0.5rem;
//   input {
//     width: 50%;
//   }
//   button {
//     padding: 0.5rem 1.5rem;
//     border-radius: 6px;
//     background: #7ab945;
//     color: #fff;
//     border: 1px solid #7ab945;
//     &:hover {
//       color: #7ab945;
//       background: #fff;
//       border: 1px solid #7ab945;
//     }
//   }
// }

//responsive design
@media (max-width: 460px) {
  .area-wise-property-display {
    h2 {
      font-size: 1.2rem;
    }
  }
}

@media (min-width: 380px) and (max-width: 415px) {
  .area-wise-property-display {
    .property-area-wise-grid {
      width: 92%;
      margin: 0 1rem;
    }
  }
}

//for no properties page

.area-wise-no-properties {
  font-family: "Poppins", sans-serif;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: justify;

    p {
      width: 50%;
      margin: 0;
    }
  }
}

@media (max-width: 460px) {
  .area-wise-no-properties {
    h2 {
      font-size: 1.2rem;
    }
  }
}
