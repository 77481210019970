.main-prop-details {
  // background-color: #527a7a;
  // background: linear-gradient(0deg, rgba(242, 243, 248, 0) 8.85%, #f2f3f8),
  //   linear-gradient(180deg, rgba(242, 243, 248, 0) 8.85%, #f2f3f8);

  background: linear-gradient(
      0deg,
      #b0aeaa 0%,
      rgba(176, 174, 170, 0) 45%,
      rgba(176, 174, 170, 0) 55%,
      #b0aeaa 100%
    ),
    linear-gradient(
      180deg,
      #b0aeaa 0%,
      rgba(176, 174, 170, 0) 45%,
      rgba(176, 174, 170, 0) 55%,
      #b0aeaa 100%
    );
  // color: white;
  color: #000;
  display: flex;
  justify-content: space-evenly;
  gap: 3rem;
  padding-bottom: 80px;
  margin-top: 3rem;

  p {
    margin: 0;
  }
}

.leftside {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  // flex: 1;
  // margin-left: 7.6rem;
  margin-left: 2rem;
}

.left-info {
  h1 {
    text-align: center;
    // margin-right: 120px;
    margin-right: 20px;
    margin-top: 58px;
  }
  p {
    text-align: justify;
    opacity: 0.9;
    max-width: 560px;
    width: 100%;
    margin: 22px 0;
    margin-bottom: 18px;
  }
}

.left-img {
  position: relative;
  width: 100%;
  max-width: 560px;
}

.left-img img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 12px;
}

.left-img .left-img-text {
  padding: 15px;
  position: absolute;
  bottom: 0px;
  color: #fff;
  background-color: rgba(18, 18, 18, 0.8);
  max-width: 590px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding-left: 6px;
  text-align: center;
}

.rightside {
  // flex: 1;
  margin-top: 54px;
  margin-right: 96px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  margin-top: 60px;
}

.cards h2,
.cards p {
  margin-left: 14px;
}

.cards h2 {
  color: #7ab945;
  font-size: 28px;
  margin: 0 0.8rem;
}

.cards p {
  font-size: 14px;
  text-align: left;
}

.cards .cards-container1,
.cards .cards-container2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card1 {
  background: url("../Images/graph.svg");
}

.card6 {
  background: url("../Images/graph2.svg");
}

.card1,
.card6,
.card2,
.card3,
.card4,
.card5 {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  background-color: rgb(248, 251, 252);
  color: black;
  padding: 18px 6px;
  width: 220px;
  max-width: 100%;
  height: 215px;
  border-radius: 8px;
  text-align: start;
  border: 2px solid #071739;

  &:hover {
    transform: scale(1.1);
  }
}

.card2,
.card3,
.card4,
.card5 {
  height: 80px;
  padding: 10px 6px;
}

/* Media Queries for Responsive Design */

@media (max-width: 1199.98px) {
  .main-prop-details {
    padding-bottom: 40px;
    margin-top: 2rem;
    flex-direction: column;
    align-items: center;
  }

  .leftside {
    margin-left: 0;
    padding: 0 15px;
    align-items: center;
  }

  .left-info {
    h1 {
      margin-right: 0;
      margin-top: 30px;
      text-align: center;
    }
    p {
      max-width: none;
    }
  }

  .left-img {
    margin-bottom: 30px;
  }

  .rightside {
    margin-right: 0;
    margin-top: 0px;
  }

  .cards {
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .main-prop-details {
    padding-bottom: 40px;
    margin-top: 2rem;
  }

  .leftside {
    margin-left: 0;
    padding: 0 15px;
  }

  .left-info {
    h1 {
      text-align: center;
      margin-right: 0;
      margin-top: 30px;
    }
    p {
      max-width: none;
      width: 100%;
    }
  }

  .left-img {
    max-width: 80%;
  }

  .rightside {
    margin-top: 0px;
    margin-right: 0;
  }

  .cards {
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .main-prop-details {
    gap: 0;
  }
  .leftside {
    margin-left: 0;
    padding: 0 15px;
  }

  .left-img {
    // left: 10%;
    width: 50%;
    img {
      width: 100%;
    }
    p {
      padding-right: 25px;
    }
  }
  .rightside {
    margin-top: 30px;
    margin-right: 0;
  }

  .cards {
    // flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .leftside {
    margin-left: 0;
    padding: 0 15px;
  }

  .left-img .left-img-text {
    width: 92%;
    text-wrap: wrap;
    font-size: 10px;
    padding-bottom: 1.3rem;
    padding-top: 0.4rem;
  }
  .left-img {
    left: 0 !important;

    img {
      width: 100%;
      height: 200px;
    }

    p {
      width: 230px; /* Make the width consistent with the image */
      height: 20px;
      overflow: hidden;
      white-space: nowrap;
      // text-overflow: ellipsis;
      transition: height 0.3s ease; /* Smooth transition for height */

      &:hover {
        height: auto; /* Reveal the full text on hover */
        white-space: normal; /* Allow text to wrap */
      }
    }
  }

  .rightside {
    margin-top: 0;
    margin-right: 0;
  }

  .cards {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 460px) {
  .leftside {
    .left-img {
      .left-img-text {
        width: 89%;
      }
      img {
        height: 170px;
      }
    }
  }
}

@media (max-width: 440px) {
  .leftside {
    .left-img {
      width: 75%;
      .left-img-text {
        width: 92.5%;
      }
      img {
        height: 200px;
      }
    }
  }
}

@media (max-width: 360.98px) {
  .leftside {
    margin-left: 0;
    padding: 0 10px;

    .left-info {
      width: 90%;
    }
    .left-img {
      margin-bottom: 0;
      img {
        height: 154px;
      }
    }
  }

  .rightside {
    margin-top: 10px;
    margin-right: 0;
  }

  .cards {
    flex-direction: column;
    align-items: center;
    padding: 2px 6px;

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 11px;
    }
    .card1,
    .card2,
    .card3,
    .card4,
    .card5,
    .card6 {
      width: 126px;
      max-width: 100%;
      height: auto;
    }
  }
}
