.property-areawise-main-container {
  background: #f2f6fc;
  width: 100%;
  height: 100%;
  padding-top: 2rem;

  // .property-areawise-container {
  .areawise-heading {
    h2 {
      margin-top: 0;
    }
  }
  .areawise-property-list {
    margin-top: 2rem;
    .ul-area-list {
      margin-bottom: 0;
      padding-bottom: 2rem;
      width: auto;
      display: flex;
      height: 250px;
      margin-left: 3rem;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 0.5rem;
      //   align-items: center;
      .areas-list {
        list-style: none;
        text-align: left;
        .area-link {
          color: black;
          text-decoration: none;
        }
      }
    }
  }
  // }
}

//responsive design

@media (max-width: 769px) {
  .property-areawise-main-container {
    .areawise-property-list {
      .ul-area-list {
        margin-left: 0;
        height: 378px;
        padding-bottom: 1rem;
      }
    }
  }
}

@media (max-width: 575px) {
  .property-areawise-main-container {
    .areawise-property-list {
      .ul-area-list {
        margin-left: 0;
        overflow-x: scroll;
        column-gap: 4rem;
        height: 300px;
        padding-bottom: 1rem;
      }
    }
  }
}

@media (max-width: 450px) {
  .property-areawise-main-container {
    .areawise-property-list {
      .ul-area-list {
        height: 210px;
      }
    }
  }
}
