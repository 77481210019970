html,
body {
  /* height: 100vh; */
  width: 100vw;
  /* overflow-x: hidden; */
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body.no-scroll {
  overflow: hidden;
}
/* sizing of map container */

.leaflet-container {
  height: 400px;
  width: 80%;
}

@media (max-width: 768px) {
  .leaflet-container {
    width: 95%;
  }
}

@media (max-width: 560px) {
  .leaflet-container {
    width: 100%;
  }
}

.cluster-icon {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(-25%, -25%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 1.5rem;
}

#root {
  height: 100%;
  width: 100%;
}
