.channel-main-container {
  position: relative;
  overflow: hidden;
  height: 500px;
  display: flex;
  gap: 1rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("../Images/partnerbg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.5;
    z-index: -1;
  }
  .channel-partner-heading {
    text-align: left;
    margin: 2rem 0 3rem 4rem;
  }
  .channel-partner-details-div {
    width: 100%;
    h2,
    p {
      text-align: left;
      margin-left: 4rem;
    }

    h2 {
      font-size: 22px;
    }
    p {
      width: 60%;
      font-weight: 500;
      font-size: 16px;
    }
    span {
      color: #7ab945;
    }
  }

  .channel-partner-second-form-div {
    margin: 3.2rem 4rem 2rem 0;
    border-radius: 12px;
    width: 55%;
    max-height: 100%;
    height: 400px;
    background: rgb(0, 0, 0, 0.5);
    overflow-y: scroll;

    .channel-partner-second-form {
      display: flex;
      gap: 1rem;

      .c-p-line-second {
        margin: 0;
        color: #fff;
        font-weight: 700;
        // letter-spacing: 0.5px;
      }

      .cp-input-files {
        color: #fff;
        // border: none;
      }
      .c-p-line-third {
        margin: 0;
        color: #fff;
        font-size: 14px;
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        // justify-content: center;
        align-items: flex-start;
        margin-left: 1.5rem;

        select {
          width: 91%;
          height: auto;
          padding: 0.65rem 0.3rem;
          border-radius: 6px;
          font-size: 14px;
        }

        label {
          color: #fff;
          text-align: left;
        }
        input {
          width: 84%;
        }
      }
      .c-partner-second-submit-btn {
        width: 92%;
        padding: 0.6rem 0.5rem;
        background: #7ab945;
        color: #fff;
        font-size: 16px;
        letter-spacing: 0.2rem;
        border: #7ab945;
        border-radius: 6px;
      }
    }
  }
}

//Responsive Design

@media (min-width: 1900px) and (max-width: 1965px) {
  .channel-main-container {
    .channel-partner-second-form-div {
      width: 37%;
      .channel-partner-second-form {
        margin-left: 0.5rem;
        .c-partner-second-submit-btn {
          width: 90%;
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .channel-main-container {
    height: 100%;
    .channel-partner-heading-details {
      width: 80%;
    }
    .channel-partner-second-form-div {
      margin: 4.2rem 0rem 2rem 0;
    }
  }
}

@media (max-width: 768px) {
  .channel-main-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .channel-partner-second-form-div {
      margin: 3.2rem 0rem 2rem 0;
    }
    .channel-partner-heading {
      text-align: center;
      margin-left: 0;
    }
    .channel-partner-details-div {
      h2,
      p {
        text-align: center;
        margin-left: 0;
      }
      p {
        text-align: justify;
        width: 90%;
        margin-left: 2rem;
      }
    }
  }
}

@media (max-width: 565px) {
  .channel-main-container .channel-partner-details-div {
    p {
      margin-left: 1rem;
    }
  }
  .channel-main-container .channel-partner-second-form-div {
    width: 75%;
    margin: 3.25rem;
  }
}
