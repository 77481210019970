.s-property-developer-main-container {
  margin-left: 14rem;
  margin-top: 1.6rem;
  h3,
  p {
    margin: 0;
  }

  hr {
    width: 82%;
    margin-left: 0;
    opacity: 0.5;
  }
  .s-property-bank-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 3rem;

    h3 {
      text-align: left;
    }

    div {
      display: flex;
      gap: 1rem;
      .s-property-home-loan,
      .s-property-emi-calc {
        a {
          text-decoration: none;
          color: #262626;
          cursor: pointer;
        }
        display: flex;
        align-items: center;
        gap: 4rem;
        padding: 1rem 0.5rem 1rem 2rem;
        background: #daeaf9;
        width: 26%;
        height: 20px;
        border: 1px solid #8f98ab;
        border-radius: 6px;
      }
      .s-property-emi-calc {
        gap: 4.7rem;
      }
      .s-prop-icons {
        width: 30px;
        height: 30px;
        opacity: 0.5;
      }
    }
  }

  .s-property-developer-container {
    display: flex;
    flex-direction: row;
    gap: 6.3rem;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2.8rem;
    .s-property-developer-left {
      h3 {
        text-align: left;
        margin-bottom: 1.5rem;
      }

      section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        // justify-content: flex-start;
        align-items: center;
        margin-top: 1rem;
        border: 1px solid rgb(76, 97, 135, 0.5);
        padding: 1rem 1.5rem 1rem 1.5rem;
        border-radius: 12px;
        width: 100%;
        height: auto;
        box-shadow: rgb(76, 97, 135, 0.3) 0px 2px 8px 0px;

        .d-logo-img {
          width: 100px;
        }

        p {
          &:last-child {
            font-size: 0.8rem;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            gap: 0.5rem;
          }
        }
      }
    }
    .s-property-developer-right {
      width: 100%;

      .developer-right-1 {
        display: flex;
        flex-direction: row;
        gap: 4.5rem;
        margin-top: 4rem;
        width: 100%;

        div {
          display: flex;
          flex-direction: column;
          // align-items: flex-start;
          // justify-content: flex-start;

          p {
            width: 100%;
            text-align: left;
            font-size: 0.85rem;
            font-weight: 600;
            color: #262626;

            &:first-child {
              font-weight: 700;
            }
            &:last-child {
              font-size: 1rem;
              opacity: 0.8;
            }
          }
        }
      }

      .s-property-developer-info {
        max-width: 600px; /* Adjust as needed */
        margin: 0;
        padding: 2rem 0;
        // border: 1px solid #ccc;
        border-radius: 8px;
        p {
          text-align: left;
          font-size: 0.9rem;
        }

        .description-text {
          overflow: hidden;
          transition: max-height 0.3s ease-out;
        }

        .description-text.expanded {
          max-height: none; /* Expand to show full content */
        }

        .toggle-button {
          background-color: #007bff;
          color: white;
          border: none;
          padding: 10px 20px;
          margin-top: 10px;
          cursor: pointer;
          border-radius: 4px;
        }
      }
    }
  }
}

/* Media queries for responsiveness */

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .s-property-developer-main-container {
    margin-left: 10rem; /* Adjust as needed */
  }
}

@media (max-width: 1025px) {
  .s-property-developer-main-container {
    margin-left: 2rem;
  }
}

@media (max-width: 992px) {
  .s-property-developer-main-container {
    margin-left: 2rem; /* Adjust as needed */
  }

  .s-property-developer-container {
    // flex-direction: column ;
    gap: 1rem;
    .s-property-developer-right {
      .developer-right-1 {
        // flex-direction: column ;
        gap: 1rem !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .s-property-developer-main-container {
    margin-left: 2rem; /* Adjust as needed */
  }

  .s-property-bank-container {
    flex-direction: column;
    gap: 1rem;
  }

  .s-property-home-loan,
  .s-property-emi-calc {
    flex: 1 1 100%; /* Stack items on smaller screens */
  }
}

@media (max-width: 576px) {
  .s-property-developer-main-container {
    margin-left: 1rem; /* Adjust as needed */
  }

  .s-property-developer-container {
    gap: 1rem;
  }
  .s-property-developer-main-container .s-property-bank-container div {
    .s-property-home-loan {
      gap: 1rem;
    }
    .s-property-emi-calc {
      gap: 0.7rem;
    }
  }

  .s-property-developer-main-container hr {
    width: 95%;
  }
  .developer-right-1 {
    gap: 1rem !important;
  }
}

@media (max-width: 460px) {
  .s-property-developer-main-container {
    margin-left: 0;
    hr {
      width: 100%;
    }
    .s-property-developer-container {
      gap: 3rem;
      .s-property-developer-left {
        width: 50%;
        section {
          gap: 0.5rem;
          padding: 0.5rem 1rem;

          .devolper-name {
            font-size: 0.8rem;
            font-weight: 600;
          }
          .d-logo-img {
            width: 70px;
          }

          p {
            &:last-child {
              gap: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 395px) {
  .s-property-developer-main-container {
    margin-left: 1rem;
  }
}

@media (max-width: 376px) {
  .s-property-developer-main-container {
    .s-property-bank-container {
      div {
        gap: 0.5rem;
      }
    }
  }
}

@media (max-width: 330px) {
  .s-property-developer-main-container {
    .s-property-developer-container {
      margin-bottom: 0;
      .s-property-developer-right {
        .s-property-developer-info {
          padding: 1rem 0;
        }
        .developer-right-1 {
          flex-wrap: wrap;
        }
      }
    }
  }
}
