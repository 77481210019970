/* Namespaced and BEM-styled classes */
.homeLoan-container {
  /* background-image: url("../Images/home-loan-bg3.jpg"); */
  /* background-size: cover; */
  /* background-position: left; */
  width: 100vw;
  height: 80vh;
  margin: 0;
  padding: 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../Images/home-loan-bg3.jpg");
    background-size: cover; /* Adjusted to cover entire container */
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.8; /* Adjust opacity here */
    z-index: 0; /* Ensures the background is behind the content */
    pointer-events: none; /* Ensure background does not interfere with content interaction */
  }
}

.homeLoan-container__relay {
  height: 80vh;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  z-index: 0;
}

.homeLoan-container__relay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.4
  ); /* Adjust opacity here for lighter effect */
  z-index: 0;
}

.homeLoan-container__side-portion {
  z-index: 2;
  display: flex;
  gap: 4rem;
  flex-direction: column;
  justify-content: center;

  h2 {
    color: #071739;
    font-size: 1.7rem;
    font-weight: 700;
    margin-left: 3.5rem;
    width: 100%;
  }
}

.homeLoan-container__form {
  z-index: 2;
  margin: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.homeLoan-container__form form {
  /* border: 1px solid #7ab945; */
  border-radius: 10px;
  padding: 1rem 0 3rem 0;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  background-color: rgb(0, 0, 0, 0.5);
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

  .home-loan-input {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: auto;
    gap: 0.4rem;
    justify-content: center;
    align-items: center;
    label {
      color: #fff;
      font-size: 1rem;
      font-weight: 700;
    }
  }
}

/* .homeLoan-container__form input, */
.homeLoan-container__form select,
.homeLoan-container__form button {
  width: 64%;
  padding: 0.5rem 5px;
  height: 2.45rem;
  font-size: 1rem;
  font-weight: 500;
  color: rgb(7, 23, 57, 0.7);
  border: 1px solid #7ab945;
  border-radius: 5px;
}

.homeLoan-container__form .label-item {
  color: black;
  align-self: center;
  font-size: 15px;
  font-weight: 500;
}

.homeLoan-container__form button {
  margin-top: 5px;
  background-color: #7ab945;
  color: white;
  border: none;
}

.homeLoan-container__form select {
  margin: 0.8rem 0;
  margin-top: 1.2rem;
}

.homeLoan-container__form button:hover {
  background-color: white;
  color: #7ab945;
  border: 1px solid #7ab945;
}
.aneme {
  margin-top: -3rem;
  height: 125px;
}

.banking-logos {
  display: flex;
  /* gap: 1rem; */
  justify-content: space-around;
}
.banking-logos div {
  border-radius: 8px;
}
.banking-logos img {
  padding: 0.4rem;
  width: 90%;
  height: 50px;
  border-radius: 12px;
}

/* Media Queries for Responsive Design */

@media (max-width: 1500px) {
  .homeLoan-container,
  .homeLoan-container__relay::after {
    height: 100vh;
  }
}

@media screen and (max-width: 1024px) {
  .homeLoan-container,
  .homeLoan-container__relay::after {
    height: 90vh;
  }
  .homeLoan-container__side-portion {
    h2 {
      margin-left: 0;
    }
  }
  .homeLoan-container__relay {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .homeLoan-container__form form {
    width: 60%;
  }

  .homeLoan-container__side-portion {
    h2 {
      margin-left: 0 !important;
    }
  }
  .banking-logos {
    flex-wrap: wrap;
  }

  .banking-logos div {
    flex-basis: 45%; /* Adjust width of banking logos container on smaller screens */
    margin-bottom: 10px; /* Add space between banking logos */
  }
}

@media screen and (max-width: 500px) {
  .banking-logos {
    margin-top: 3rem;
    gap: 0 3.5rem;
    justify-content: center;
  }
  .homeLoan-container__form {
    margin-top: 5rem;
  }
  .homeLoan-container__form form {
    width: 100%;

    h4 {
      width: 80%;
    }
  }

  .banking-logos div {
    flex-basis: auto;
  }

  .banking-logos img {
    width: auto;
  }
  .homeLoan-container {
    height: 100vh;
    width: 100%;
  }
  .homeLoan-container__form input,
  .homeLoan-container__form select,
  .homeLoan-container__form button {
    font-size: 14px;
    padding: 8px;
  }

  .homeLoan-container__side-portion {
    h2 {
      color: #071739;
      font-size: 1.4rem;
      font-weight: 700;
      margin-left: 2.3rem !important;
      width: 80%;
    }
  }
}
@media (max-width: 450px) {
  .homeLoan-container,
  .homeLoan-container__relay::after {
    height: 105vh;
    width: 100%;
  }
}

@media (max-width: 415px) {
  .homeLoan-container,
  .homeLoan-container__relay::after {
    height: 110vh;
    width: 100%;
  }
}

@media (max-width: 400px) {
  .homeLoan-container,
  .homeLoan-container__relay::after {
    height: 118vh;
    width: 100%;
  }
}

@media (max-width: 380px) {
  .homeLoan-container,
  .homeLoan-container__relay::after {
    height: 142vh;
    width: 100%;
  }
}


