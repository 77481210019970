.footer-container {
  background: #242424;
  color: #fff;
  padding: 0;
  box-sizing: border-box;
}

.footer-details-container {
  display: flex;
  flex-direction: row;
  padding: 2rem 6rem 2rem 10rem;
  gap: 0;
  box-sizing: border-box;
}

.logo-heading-container {
  flex: 0.55;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h1 {
    text-align: left;
    margin: 0 !important;
  }

  .company-icons {
    display: flex;
    gap: 2.4rem;
    justify-content: flex-start;

    .social-media-icons {
      color: rgba(240, 240, 240, 0.9);
      font-size: 24px;
    }
  }
}

.comapany-about-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 5.5rem;

  section {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 0.5rem;
    h1,
    p {
      margin: 0;
    }

    a {
      font-size: 0.85rem;
      text-decoration: none;
      color: white;
      font-weight: 400 !important;
    }
  }
}

.footer-location-container {
  font-size: 14px;
  display: flex;
  background: #202020;
  width: 100%;
  padding: 1rem 6rem 2rem 6rem;
  box-sizing: border-box;
  gap: 15rem;

  .location-container {
    margin-top: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    section {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: flex-start;
      margin-left: 4rem;

      p {
        text-align: left;
        color: white;
        margin: 0;
        width: 100%;
      }

      .location-name {
        opacity: 0.5;
        width: 200px;
      }

      .location-desc {
        margin-left: 4px;
      }
    }
  }

  .copyright-container {
    margin-top: 3rem;
    flex: 0.9;
    display: flex;
    margin-left: 4rem;
    // margin-right: 3rem;
    flex-direction: column;
    gap: 1rem;

    .policies:last-child {
      border-left: 2px solid #fff;
      padding-left: 1rem;
    }
    p {
      color: white;
      width: fit-content;
      margin: 0;
    }

    .copyright {
      margin-left: 5.7rem;
    }

    section {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;

      p:nth-child(-n + 2) {
        border-right: 2px solid #fff;
        padding-right: 1.5rem;
      }
    }
  }
}

/* Media Queries for Responsive Design */

@media (max-width: 1200px) {
  .footer-container {
    height: auto;
  }

  .footer-details-container {
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 2rem 3rem;
    gap: 2rem;
  }

  .logo-heading-container {
    align-items: center;
  }

  .comapany-about-container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 4rem;
  }

  .footer-location-container {
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding: 1.5rem 3rem;
    gap: 2rem;
  }

  .copyright-container {
    align-items: center;
    margin-left: 0;
    margin-top: 1rem;

    .cookies-in-footer {
      text-decoration: none;
      border-left: 1px #fff;
    }
  }
}

@media (max-width: 992px) {
  .footer-details-container {
    padding: 1.5rem 2rem;
    flex-direction: column;
    align-items: center;
  }

  .comapany-about-container {
    gap: 5rem;
  }

  .footer-location-container {
    padding: 1rem 2rem;
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .footer-details-container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .logo-heading-container {
    gap: 1.5rem;
  }

  .company-icons {
    gap: 0.5rem;
  }

  .comapany-about-container {
    gap: 3rem;
    align-items: flex-start !important;
  }

  .footer-location-container {
    padding: 0.8rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1.5rem;
  }

  .copyright-container {
    margin-left: 0;
    margin-top: 1rem;
    align-items: center;

    .copyright {
      text-align: center;
      margin-left: 0 !important;
    }
  }
}

@media (max-width: 576px) {
  .footer-details-container {
    padding: 1rem 0.5rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .logo-heading-container {
    gap: 1rem;
  }

  .company-icons {
    gap: 0.3rem;
  }

  .comapany-about-container {
    gap: 2.5rem;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 5rem;
    row-gap: 1.5rem;

    section {
      align-items: center;
    }
  }

  .footer-location-container {
    padding: 0.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
  }
  .footer-location-container .copyright-container {
    margin-left: 0;
  }
}

@media (max-width: 450px) {
  .footer-location-container {
    .location-container {
      margin-left: 5rem;
      section {
        margin-left: 1rem;
      }
    }
    .copyright-container {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

@media (max-width: 395px) {
  .footer-location-container {
    .location-container {
      margin-left: 4rem;
    }
  }
}

@media (max-width: 380px) {
  .footer-location-container {
    .location-container {
      margin-left: 4.5rem;
    }
  }
}

@media (max-width: 322px) {
  .footer-location-container {
    .copyright-container {
      section {
        gap: 0.5rem;
      }
    }
  }
}
