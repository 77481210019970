/* Property Grid Container */
.propertyGrid-container {
  width: 100%;
  display: grid;
  margin: auto;
  grid-template-columns: repeat(2, 1fr); /* Default to two columns */
  justify-content: center;
  align-items: center;
  gap: 1rem;
  overflow: hidden;
}

/* Property Grid Container Children */
.propertyGrid-container > div {
  width: 100%;
}

.prop-grid-property-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.prop-grid-property-filters {
  width: 30%;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: fit-content;
  overflow: auto;
}

.prop-grid-filter-section {
  margin-bottom: 1rem;
}

.prop-grid-filter-section h5 {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  color: #333;
}

.prop-grid-filter-section div {
  margin-bottom: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
}

.prop-grid-filter-section label {
  font-size: 0.9rem;
  color: #555;
  display: flex;
  align-items: center;
}

.prop-grid-filter-section input {
  margin-right: 0.5rem;
  width: 1rem;
  accent-color: green;
}

.prop-grid-clear-filters-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.prop-grid-clear-filters-button {
  color: #7ab945;
  background-color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.prop-grid-clear-filters-button:hover {
  color: #7ab945;
  background-color: white;
  border: none;
}

.prop-grid-filter-menu {
  display: none;
  background-color: #7ab945;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
  text-align: center;
}

.prop-grid-filter-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.prop-grid-filter-modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  width: 90vw;
  max-width: 500px;
  max-height: 80%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.prop-grid-filter-close-button {
  background-color: #ffffff;
  color: rgb(176, 5, 5);
  border: none;
  font-size: 18px;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.prop-grid-filter-close-button:hover {
  background-color: white;
}

.modal {
  position: fixed;
  top: 4rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  overflow-y: auto;
  z-index: 1000; /* Ensure it's above other content */
}

.modal-content {
  // top: 220px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 300px; /* Adjust based on your design */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-controls{
  display: flex;
  justify-content: space-between;

}
.modal-controls button{
// height: 1.5rem;
padding: .5rem;
border-radius: 5px;
border: none;
background-color:#7ab945;
color: white;
}
.modal-controls button:hover{
  border: 1px solid #7ab945;
  background-color: white;
  color: #7ab945;
}
@media (max-width: 868px) {
  .propertyGrid-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens */
  }
  .prop-grid-filter-modal-content {
    margin-top: 75px;
    height: 100vh;
  }
  .prop-grid-property-filters {
    display: none; /* Hide the filters sidebar */
  }
  .prop-grid-filter-menu {
    display: block; /* Show the filter menu button */
  }
  .modal{
    top: 4rem;
  }
  .modal-content{
    top: 220px;
  }
}
.propertygrid-pagination-content{
  width: 35%;
  margin: auto;
}
@media (max-width: 768px){
  .modal{
    top: 4rem;
  }
  .modal-content{
    top: 350px;
  }
}
@media (max-width: 600px) {
  .propertyGrid-container {
    grid-template-columns: 1fr; /* One column for small screens */
  }
  .modal{
    top: 4rem;
  }
  .modal-content{
    top: 320px;
  }
}

.prop-grid-filter-modal-content {
  margin-top: 75px;
  margin-left: 0;
  margin-right: 2.6rem;
  height: 100vh;
}

.prop-grid-filter-header {
  background-color: white;
  display: flex;
  color: black;
  align-items: center;
  justify-content: space-between;
}
