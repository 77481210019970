.vision-container {
  display: flex;
  width: 60%;
  border-radius: 15px;
  height: auto; /* Full-screen height */
  color: rgb(0, 0, 0);
  /* border: 1px solid blue; */
  box-shadow: rgba(136, 165, 191, 0.48) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  font-family: 'Arial', sans-serif;
  position: relative; /* Positioning context for absolute elements */
}

.vision-left-side {
  position: relative;
  width: 50%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background: url('../../Images/vision.jpg') no-repeat center center; /* Update with actual path */
  background-size: cover;
  display: flex;
  z-index: 5;
  /* border:1px solid red; */
  flex-direction: column;
  justify-content: center; /* Center vertically within the container */
}

.vision-overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  background: rgba(3, 17, 90, 0.5); /* Semi-transparent overlay */
  z-index: 1; /* Ensure overlay is above the background image */
}

.vision-section {
  /* border :1px solid green; */
  position: relative;
  width: 100%; /* Full width of the container */
  height: 200px;
  left: 50%; /* Height to ensure circles have space */
}
.circle-title img{
  width: 70%;
  border-radius: 40%;
}
.circle {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: linear-gradient(90deg, rgba(251,251,251,1) 0%, rgba(122,185,69,1) 50%, rgba(7,23,57,1) 100%); /* Circle color */
  display: flex;
  border: .5rem solid white;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%; /* Vertically center */
  left: 50%; /* Horizontally center */
  transform: translate(-50%, -50%); /* Adjust for perfect centering */
  font-size: 24px;
  z-index: 2; /* Ensure circles are above overlay */
}

.circle-title {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  color: #ffffff !important; /* White text for contrast */
}

.hidden {
  display: none; /* Hide but keep accessible for screen readers */
}

.vision-right-side {
  /* border: 1px solid red; */
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  padding: 20px;
  background: #ffffff; /* Optional: Set a background color */
  font-size: 16px;
  text-align: center;
  position: relative; /* Ensure positioning context for circles */
}
.vision-right-side div{
  width: 80%;
  /* border: 1px solid blue; */
} 
.vision-right-side div p{
  margin-top: -1rem;
  font-size: 14px;
  text-align: justify;
  font-weight: 500;
}
.vision-right-title{
  font-size: 22px;
  text-align: left;
}
.right-circle{
  display: none;
}
@media (max-width: 922px) {

  .vision-container{
    height: auto;
    width: 70%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .vision-left-side {
    border-bottom-left-radius: none;
    border-top-right-radius: 15px;
    height: 250px;
    width: 100%;
  }
  .vision-overlay{
    flex-direction: row;
  }
  .vision-section{
    left: 0;
    top: 60%;
  }
.vision-right-side{
  width: 70%;
}
.vision-right-side div{
  width: 100%;
}
}
@media (max-width: 768px) {
  .vision-container{
    height: auto;
    width: 70%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .vision-left-side {
    border-bottom-left-radius: none;
    border-top-right-radius: 15px;
    height: 300px;
    width: 100%;
  }
  .vision-overlay{
    flex-direction: row;
  }
  .vision-section{
    left: 0;
    top: 65%;
  }
.vision-right-side{
  width: 70%;
}
.vision-right-side div{
  width: 100%;
}
.circle{
  height: 120px;
  width: 120px;
}
}
@media (max-width: 628px) {
  .vision-container{
    height: auto;
    width: 75%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .vision-left-side {
    border-bottom-left-radius: none;
    border-top-right-radius: 15px;
    height: 300px;
    width: 100%;
  }
  .vision-overlay{
    flex-direction: row;
  }
  .vision-section{
    left: 0;
    top: 65%;
  }
.vision-right-side{
  width: 70%;
}
.vision-right-side div{
  width: 100%;
}
.circle{
  height: 100px;
  width: 100px;
}
}
@media (max-width: 518px) {
  .vision-container{
    height: auto;
    width: 80%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .vision-left-side {
    border-bottom-left-radius: none;
    border-top-right-radius: 15px;
    height: 300px;
    width: 100%;
  }
  .vision-overlay{
    flex-direction: row;
  }
  .vision-section{
    left: 0;
    top: 65%;
  }
.vision-right-side{
  width: 70%;
}
.vision-right-side div{
  width: 100%;
}
.circle{
  height: 80px;
  width: 80px;
}
}
@media (max-width: 428px) {
  .vision-container{
    height: auto;
    width: 85%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .vision-left-side {
    border-bottom-left-radius: none;
    border-top-right-radius: 15px;
    height: 200px;
    width: 100%;
  }
  .vision-overlay{
    flex-direction: row;
  }
  .vision-section{
    left: 0;
    top: 55%;
  }
.vision-right-side{
  width: 70%;
}
.vision-right-side div{
  width: 100%;
}
.circle{
  height: 70px;
  width: 70px;
}
}
@media screen and (max-width:390px) {
  .vision-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-left: -2.3rem; */
    width: 90%;
  }
}
/* @media (max-width: 768px) {
  .vision-container {
    flex-direction: column;
  }

  .vision-left-side, .vision-right-side {
    width: 100%;
    height: auto;
  }

  .vision-section {
    margin-bottom: 10px; 
  }

  .circle {
    width: 100px;
    height: 100px;
    font-size: 20px; 
  }
} */
